import { type ReactNode } from 'react'
import styles from './styles.module.scss'

interface IGradientLink {
  children: ReactNode
  href: string
}

export const GradientLink = ({ children, href }: IGradientLink) => {
  return (
    <a className={styles.link} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
