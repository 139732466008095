import { Fade } from 'react-awesome-reveal'
import styles from './Footer.module.scss'

export const Footer = () => {
  return (
    <footer className={styles.body}>
      <Fade direction="up" damping={0.1} cascade triggerOnce>
        <h5>
          <img src="/assets/img/icons/danger.svg" alt="danger" />
          Уведомление о рисках
        </h5>

        <p className="text">
          Торговля на финансовых, фондовых, или крипто рынках сопряжена с
          повышенными рисками о потере денежных средств. Вы должны понимать, что
          любые высокорискованные операции могут повлечь за собой полную, либо
          частичную потерю денежных средств, при торговле на вышеперечисленных
          рынках, в случае, если Вы продолжаете использовать данный сайт, вы
          соглашаетесь и осознаёте, что вся информация размещенная на сайте,
          выступает лишь в качестве ознакомительных материалов, и не является
          прямым, либо косвенным призывом к действиям на финансовых, фондовых,
          либо крипто рынкам которые могут повлечь за собой возможную потерю
          ваших средств. Соглашаясь с уведомлениями о рисках, вы осознаете и
          принимает все возможные риски на себя.
        </p>

        <span className="text">
          Данный сайт, и вся информация размещенная на сайте, является
          субъективным мнением автора про анализ графика, про торговлю на
          финансовом, фондовом, либо крипто рынке, и про интерпретацию как и где
          должна проходить торговля в общем и целом. Автор не несёт никакой
          ответственности за то, что информация размещенная на сайте, может быть
          не правильно интерпретирована конечным пользователем сайта, что может
          повлечь за собой, полную, либо частичную потерю личных средств, в
          процессе торгов на финансовых, фондовых, либо крипто рынках
        </span>
      </Fade>
    </footer>
  )
}
