import { Fade } from 'react-awesome-reveal'
import styles from './OurAcademy.module.scss'
import { ANCHOR } from '@enums/anchor'

const cards = [
  {
    title: 'Садик трейдера',
    lessons: 24,
    video: 12,
    difficulty: 'Легко',
    access: 'Бесплатный',
    icon: '/assets/img/icons/book.png',
    decoration: '',
  },
  {
    title: 'Школа трейдинга',
    lessons: 24,
    video: 12,
    difficulty: 'Средне',
    access: 'Бесплатный',
    icon: '/assets/img/icons/backpack.png',
    decoration: '',
  },
  {
    title: 'Университет обучения',
    lessons: 24,
    video: 12,
    difficulty: 'Сложно',
    access: 'Бесплатный',
    icon: '/assets/img/icons/teacher.png',
    decoration: '',
  },
  {
    title: 'Академия знаний',
    lessons: 24,
    video: 12,
    difficulty: 'Сверхсложно',
    access: 'Бесплатный',
    icon: '/assets/img/icons/diploma.png',
    decoration: '',
  },
]

export const OurAcademy = () => {
  return (
    <div id={ANCHOR.ACADEMY} className="landing_wrapper">
      <Fade direction="up" damping={0.2} cascade triggerOnce>
        <div className={styles.title}>
          <h2 className="sub_title_l">Наша академия</h2>
          <p className="text">
            В этих обучающих материалах собрано сотни статей, и десятки видео
            уроков от трейдера Smoke FX, которые помогут каждому желающему
            освоить такое направление как заработок на финансовом, фондовом, или
            крипто рынке!
          </p>
        </div>
      </Fade>
      <div className={styles.cards} style={{ backdropFilter: 'blur(14px)' }}>
        <Fade direction="up" damping={0.1} cascade triggerOnce>
          {cards.map((card, i) => (
            <div key={card.title} className="border_element">
              <div className={styles.cards_card}>
                <div className={styles.cards_card_header}>
                  <div className={styles.cards_card_header_icon}>
                    <img src={card.icon} alt="decoration" />
                  </div>

                  <p className="text_accent">{card.title}</p>
                </div>
                <div className={styles.cards_card_body}>
                  <div className={styles.cards_card_body_item}>
                    <div className={styles.cards_card_body_item_left}>
                      <img
                        src="/assets/img/icons/youtube.svg"
                        alt="decoration"
                      />
                      <p>Видео материал:</p>
                    </div>
                    <p className={styles.cards_card_body_item_right}>
                      {card.video}
                    </p>
                  </div>
                  <div className={styles.cards_card_body_item}>
                    <div className={styles.cards_card_body_item_left}>
                      <img
                        src="/assets/img/icons/chart_min.svg"
                        alt="decoration"
                      />
                      <p>Сложность:</p>
                    </div>
                    <p className={styles.cards_card_body_item_right}>
                      {card.difficulty}
                    </p>
                  </div>
                  <div className={styles.cards_card_body_item}>
                    <div className={styles.cards_card_body_item_left}>
                      <img
                        src="/assets/img/icons/access.svg"
                        alt="decoration"
                      />
                      <p>Доступ:</p>
                    </div>
                    <p className={styles.cards_card_body_item_right}>
                      {card.access}
                    </p>
                  </div>
                  <div className={styles.cards_card_body_item}>
                    <div className={styles.cards_card_body_item_left}>
                      <img
                        src="/assets/img/icons/book_min.svg"
                        alt="decoration"
                      />
                      <p>Уроки:</p>
                    </div>
                    <p className={styles.cards_card_body_item_right}>
                      {card.lessons}
                    </p>
                  </div>
                </div>

                <a
                  className={styles.cards_card_button}
                  href="https://t.me/+3FY9hKLcnghhOWJi"
                  target="_blank"
                  rel="noreferrer">
                  <div className={styles.cards_card_button_inner}>
                    <div>Получить доступ</div>
                  </div>
                </a>

                <div className={styles.cards_card_chart}>
                  <img
                    src={`/assets/img/landing/chart_${i + 1}.svg`}
                    alt="chart"
                  />
                </div>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </div>
  )
}
