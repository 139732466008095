import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@utils/routes'
import { useEffect } from 'react'

export const RedirectToGeneralRout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(ROUTES.MAIN)
  }, [navigate])

  return <></>
}
