import { OurAcademy } from './OurAcademy/OurAcademy'
import { Resources } from './Resources'
import { Community } from './Community'
import { Telegram } from './Telegram'
import { Welcome } from './Welcome'
import './styles.scss'
import { ParticlesWrapper } from './ParticlesWrapper'

export const Main = () => {
  return (
    <div>
      <Welcome />
      <OurAcademy />
      <Resources />
      <ParticlesWrapper>
        <Telegram />
        <Community />
      </ParticlesWrapper>
    </div>
  )
}
