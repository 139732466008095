import { ROUTES } from '@utils/routes'
import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LandingLayout } from 'components/layout/Landing'
import { Main } from '@pages/Landing/Main'
import { RedirectToGeneralRout } from '@pages/RedirectToGeneralRout'

const RoutesWrapper = () => {
  return (
    <Routes>
      <Route path={ROUTES.MAIN} element={<LandingLayout />}>
        <Route index element={<Main />} />
      </Route>
      <Route path={ROUTES.ALL} element={<RedirectToGeneralRout />} />
    </Routes>
  )
}

export default memo(RoutesWrapper)
