import styles from './Community.module.scss'
import Reveal from 'react-awesome-reveal'
import { keyframes } from '@emotion/react'

const customAnimation = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0%, 0);
  }
`

export const Community = () => {
  return (
    <Reveal keyframes={customAnimation}>
      <div className="landing_wrapper">
        <div className={styles.wrapper}>
          <div className={styles.body}>
            <h2>Хочешь стать частью команды?</h2>
            <p className="text">
              Напиши нам в чат бота, и стань участником сообщества за 1 минуту
            </p>
            <div className={styles.body_links}>
              <a
                href="https://t.me/SmokeFXchatbot"
                className={styles.link}
                target="_blank"
                rel="noreferrer">
                <div className={styles.body_links_inner}>
                  <img
                    src="/assets/img/icons/telegram_biege.svg"
                    alt="telegram"
                  />
                  <span className="text_light">Написать в Telegram</span>
                </div>
              </a>
              <a
                href="https://www.youtube.com/@smoke_fx"
                className={styles.link}
                target="_blank"
                rel="noreferrer">
                <div className={styles.body_links_inner}>
                  <img
                    src="/assets/img/icons/youtube_biege.svg"
                    alt="telegram"
                  />
                  <span className="text_light">Подписаться на Youtube</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Reveal>
    /* </Fade> */
  )
}
