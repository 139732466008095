import { Fade } from 'react-awesome-reveal'
import styles from './Telegram.module.scss'
import { ANCHOR } from '@enums/anchor'

const items = [
  {
    img: '/assets/img/icons/bookmark.png',
    title: 'База знаний ',
    text: 'Smoke FX разработал десятки бесплатных курсов для всех трейдеров в открытом доступе!',
  },
  {
    img: '/assets/img/icons/chart.png',
    title: 'Бесплатный софт ',
    text: 'Более 100 авторских алгоритмов от Smoke FX уже помогают выстраивать стратегию для тысяч людей!',
  },
  {
    img: '/assets/img/icons/dialog.png',
    title: 'Общение 24/7',
    text: 'Живые диалоги и днём и ночью, становись профессионалом благодаря лучшему комьюнити в мире!',
  },
]

export const Telegram = () => {
  return (
    <div>
      <div className="landing_wrapper">
        <div className={styles.body}>
          <Fade className={styles.order} direction="left" damping={0.2} cascade triggerOnce>
            <div className={styles.body_img}>
              <img src="/assets/img/landing/phone.svg" alt="decoration" />
            </div>
          </Fade>
          <Fade direction="right" damping={0.2} cascade triggerOnce>
            <div id={ANCHOR.TELEGRAM} className={styles.body_text}>
              <h2 className="sub_title_l">Telegram форум</h2>
              <p className="text">
                Мы собрали десятки тысяч трейдеров разного уровня знаний, опыта,
                и дохода, что бы объединить их в одно большое и доброжелательное
                комьюнити, присоединяйся к лучшим, что бы стать лучшим
              </p>

              <div className={styles.body_items}>
                {items.map(item => (
                  <div key={item.title} className={styles.body_items_item}>
                    <img
                      width={58}
                      height={58}
                      src={item.img}
                      alt="decoration"
                    />
                    <div className={styles.body_items_item_info}>
                      <p className="">{item.title}</p>
                      <span className="text">{item.text}</span>
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.body_button}>
                <a
                  href="https://t.me/+3FY9hKLcnghhOWJi"
                  target="_blank"
                  className="landing_btn"
                  rel="noreferrer">
                  Перейти на форум
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
