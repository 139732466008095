import { type ReactNode } from 'react'

import styles from './styles.module.scss'

interface IParticlesWrapper {
  children: ReactNode
}

export const ParticlesWrapper = ({ children }: IParticlesWrapper) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.decorations}>
        <div className={styles.decorations_decoration}></div>
        <div className={styles.decorations_decoration}></div>
        <div className={styles.decorations_decoration}></div>
        <div className={styles.decorations_decoration}></div>
        <div className={styles.decorations_decoration}></div>
        <div className={styles.decorations_decoration}></div>
      </div>

      {children}
    </div>
  )
}
