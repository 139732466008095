import clsx from 'clsx'
import styles from './Welcome.module.scss'
import { Fade } from 'react-awesome-reveal'

const cards = [
  {
    icon: '/assets/img/icons/user.png',
    title: 'Комьюнити',
    count: '310.000',
  },
  {
    icon: '/assets/img/icons/camera.png',
    title: 'Видео обучения',
    count: '848',
  },
  {
    icon: '/assets/img/icons/documents.png',
    title: 'Статьи',
    count: '117',
  },
  {
    icon: '/assets/img/icons/circle.png',
    title: 'Партнёров',
    count: '17.899',
  },
]

export const Welcome = () => {
  return (
    <div className="landing_wrapper">
      <div className={styles.body}>
        <div className={styles.body_decorations}>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
          <div className={styles.body_decorations_decoration}></div>
        </div>
        <Fade direction="left" damping={0.2} cascade triggerOnce>
          <div className={styles.body_left}>
            <div className={styles.body_left_info}>
              <Fade direction="left" damping={0.2} cascade triggerOnce>
                <h4 className={clsx('text_accent')}>Добро пожаловать в</h4>
                <h1 className={clsx('title_l')}>Академию трейдинга</h1>
                <p className={clsx('text')}>
                  Это лучший ресурс, который был создан трейдером для трейдеров,
                  здесь собрана вся важная информация про финансовый рынок,
                  которая необходима для успешного старта становления себя
                  трейдером!
                </p>
                <p className={clsx('text_light_welcome')}>
                  Присаживайся поудобнее, мы отправляемся прямо to the moon!
                </p>
                <div className={styles.body_left_buttons}>
                  <a
                    href="https://t.me/+3FY9hKLcnghhOWJi"
                    target="_blank"
                    className="landing_btn"
                    rel="noreferrer">
                    <span className={styles.body_left_buttons_button_inner}>
                      Перейти на канал
                    </span>
                  </a>

                  <a
                    href="https://www.youtube.com/@smoke_fx"
                    target="_blank"
                    rel="noreferrer">
                    <div className={styles.body_left_buttons_youtube}>
                      <span className="text_accent">Перейти на Youtube</span>
                      <img
                        width={44}
                        height={44}
                        src="/assets/img/icons/go_to_youtube.png"
                        alt="go to youtube"
                      />
                    </div>
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </Fade>
        <Fade
          style={{ zIndex: 11 }}
          direction="right"
          damping={0.2}
          cascade
          triggerOnce>
          <div className={styles.body_right}>
            <img
              width={677}
              height={420}
              className={styles.body_right_img}
              src="/assets/img/landing/trading.png"
              alt="decoration"
            />

            <img
              className={styles.body_right_img_mobile}
              src="/assets/img/landing/trading_mobile.png"
              alt="decoration"
            />
          </div>
        </Fade>
      </div>
      <div style={{ backdropFilter: 'blur(14px)' }}>
        <Fade direction="up" damping={0.2} cascade triggerOnce>
          <div className={clsx('border_element', styles.cards)}>
            {cards.map((card, i) => (
              <>
                <div className={styles.cards_card}>
                  <div className={styles.cards_card_icon}>
                    <img src={card.icon} alt="decoration" />
                  </div>
                  <div className={styles.cards_card_info}>
                    <p>{card.title}</p>
                    <span>{card.count}</span>
                  </div>
                </div>
                {i !== cards.length - 1 && (
                  <div className={styles.cards_card_separator}></div>
                )}
              </>
            ))}
          </div>
        </Fade>
      </div>
    </div>
  )
}
