import { GradientLink } from '@parts/GradientLink'
import styles from './Resources.module.scss'
import clsx from 'clsx'
import { Fade } from 'react-awesome-reveal'
import { ANCHOR } from '@enums/anchor'

const cards = [
  {
    img: '/assets/img/landing/ai.png',
    title: 'Smoke FX X-Ray',
    text: 'ИИ на основе 180 параметров технического, объёмного, и свечного анализа',
    href: 'https://t.me/SmokeFXchatbot',
    linkText: 'Получить доступ',
  },
  {
    img: '/assets/img/landing/community.png',
    title: 'Smoke FX FREE GROUP',
    text: 'Cамое крупное сообщество трейдеров по аналитике на фин. рынке в таймфрейме.',
    href: 'https://t.me/+3FY9hKLcnghhOWJi',
    linkText: 'Перейти',
  },
  {
    img: '/assets/img/landing/youtube_chanel.png',
    title: 'YouTube канал',
    text: 'Сотни торговых видео с реальной практикой и отработкой на рынке',
    href: 'https://www.youtube.com/@smoke_fx',
    linkText: 'Перейти',
  },
  {
    img: '/assets/img/landing/ai_2.png',
    title: 'Робот AI',
    text: 'Получи доступ к самым технологичным помощникам для каждого трейдера за 1 минуту',
    href: 'https://t.me/SmokeFXchatbot',
    linkText: 'Получить доступ',
  },
]

export const Resources = () => {
  return (
    <div id={ANCHOR.RESOURCES}>
      <div className="landing_wrapper">
        <div className={styles.body}>
          <Fade direction="up" damping={0.2} cascade triggerOnce>
            <div className={styles.body_header}>
              <h2 className="sub_title_l">Наши ресурсы</h2>
              <p className="text">
                За 12 лет работы в сфере трейдинга, мы разработали десятки
                сервисов для работы, и ниже представлены самые основные из них,
                получайте доступ менее чем за 1 минуту просто нажав на кнопку!
              </p>
            </div>
          </Fade>
          <div
            className={styles.body_cards}
            style={{ backdropFilter: 'blur(6px)' }}>
            <Fade direction="up" damping={0.05} cascade triggerOnce>
              {cards.map(card => (
                <div
                  key={card.title}
                  className={clsx('border_element', styles.body_cards_wrapper)}>
                  <div className={styles.body_cards_card}>
                    <div className={styles.body_cards_card_img_wrapper}>
                      <img
                        className={styles.body_cards_card_img}
                        src={card.img}
                        alt="decoration"
                      />
                    </div>
                    <div className={styles.body_cards_card_info}>
                      <h4 className="title_s">{card.title}</h4>
                      <p className="text">{card.text}</p>
                      <div className={styles.body_cards_card_info_link}>
                        <GradientLink href={card.href}>
                          {card.linkText}
                        </GradientLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
