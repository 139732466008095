import { Outlet } from 'react-router-dom'
import { Footer } from './Footer'
import { Header } from './Header'

import styles from './LandingLayout.module.scss'
import clsx from 'clsx'
// import { Fade } from 'react-awesome-reveal'

export const LandingLayout = () => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.circle, styles.circle_1)} />
      <div className={clsx(styles.circle, styles.circle_2)} />
      <div className={clsx(styles.circle, styles.circle_3)} />
      <div className={clsx(styles.circle, styles.circle_4)} />
      <div className={clsx(styles.circle, styles.circle_5)} />
      <div className={styles.candles}></div>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
