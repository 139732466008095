import { ROUTES } from '@utils/routes'
import styles from './Header.module.scss'
import { Fade } from 'react-awesome-reveal'
import { ANCHOR } from '@enums/anchor'

const anchors = [
  {
    label: 'Наша академия',
    anchor: ANCHOR.ACADEMY,
  },
  {
    label: 'Наши ресурсы',
    anchor: ANCHOR.RESOURCES,
  },
  {
    label: 'Telegram форум',
    anchor: ANCHOR.TELEGRAM,
  },
]

export const navItems = [
  {
    text: 'Главная',
    url: ROUTES.MAIN,
  },
]

const handleScroll = (anchor: string) => {
  return () => {
    const element = document.getElementById(anchor)
    if (element) {
      const { top } = element.getBoundingClientRect()

      window.scroll({
        top: top - 50,
        behavior: 'smooth',
      })
    }
  }
}

export const Header = () => {
  return (
    <Fade direction="down" damping={0.2} cascade triggerOnce>
      <div className="landing_wrapper">
        <header className={styles.body}>
          <div className={styles.body_logo}>
            <a href="/" target="_blank" rel="noreferrer">
              <img src="/assets/img/logo.svg" alt="logo" />
            </a>
          </div>

          <div className={styles.body_anchors}>
            <Fade direction="down" damping={0.2} cascade triggerOnce>
              {anchors.map(({ anchor, label }) => (
                <span onClick={handleScroll(anchor)} key={anchor}>
                  {label}
                </span>
              ))}
            </Fade>
          </div>
          <div className={styles.body_button}>
            <a
              href="https://t.me/+3FY9hKLcnghhOWJi"
              target="_blank"
              className="landing_btn"
              rel="noreferrer">
              <div className={styles.body_button_inner}>
                <img src="/assets/img/icons/telegram_dark.svg" alt="telegram" />
                <span>Наш Телеграм</span>
              </div>
            </a>
          </div>
          <div className={styles.body_button_mobile}>
            <a
              href="https://t.me/+3FY9hKLcnghhOWJi"
              target="_blank"
              rel="noreferrer">
              <img src="/assets/img/icons/telegram_light.svg" alt="telegram" />
            </a>
          </div>
        </header>
      </div>
    </Fade>
  )
}
